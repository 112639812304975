<template>
  <section
    v-if="documentData && documentData.data && documentData.data.length"
    class="invoice-preview-wrapper"
  >
    <div
      id="exportArea"
      class="exportArea"
    >
      <!--Header-->
      <div
        class="page-header"
        style="text-align: center"
      >
        <div
          class="logo-wrapper"
          :style="{ marginBottom: '0px' }"
        >
          <b-img
            fluid
            :src="cmsData.favicon"
            alt="Fems"
            :style="{ height: '90px' }"
            class="float-left pos"
          />
        </div>
        <div>
          <h1>{{ cmsData.name }}</h1>
        </div>
        <div>
          <h4>{{ cmsData.address }}</h4>
        </div>
        <div>
          <h4>Phone No. : {{ cmsData.phone_no }}</h4>
        </div>
        <div>
          <h4>Mobile No. : {{ cmsData.mobile_no }}</h4>
        </div>
        <div
          class="mt-1"
          style="font-size: 22px"
        >
          <h2>Head Wise Summaries ({{ paymentStatus===1 ? 'Paid' : paymentStatus===2 ? "Due" : "N/A" }} Report)</h2>
          ( <b>From:</b> {{ documentData.heading.from_date }} - <b>To:</b>
          {{ documentData.heading.to_date }} )
        </div>
        <div style="margin-bottom: 5px; text-align: left">
          <b>Total Data: {{ documentData.total }}</b>
        </div>
      </div>
      <!--Footer-->
      <div class="page-footer">
        <div class="d-flex justify-content-between">
          <div>
            <b>Print Date</b>
            <span
              class="ml-text1"
            >: {{ showLocalDateTime(currentDateTime) }}
            </span>
          </div>
          <div :style="{ paddingRight: '90px' }">
            <b>Powered BY</b>
            <span
              class="ml-text3"
            >: {{ cmsData.powered_by || "fitl.com.bd" }}
            </span>
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <td>
              <!--place holder for the fixed-position header-->
              <div class="page-header-space" />
            </td>
          </tr>
        </thead>

        <table
          id="printTable"
          class="pageContent"
        >
          <thead>
            <tr class="left-padding">
              <th
                width="50px"
                class="left-padding"
              >
                SL.
              </th>
              <th
                width="280px"
                class="left-padding"
              >
                Head Name
              </th>
              <th
                width="130px"
                class="left-padding"
              >
                Campus
              </th>
              <th
                width="360px"
                class="left-padding"
              >
                Class - Shift - Section
              </th>
              <th
                width="190"
                class="right-padding"
              >
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in documentData.data"
              :key="item.id"
              class="left-padding"
            >
              <td class="left-padding">
                {{ ++index }}
              </td>
              <td class="left-padding">
                {{ item.gl_name }}
              </td>
              <td class="left-padding">
                {{ item.branch }}
              </td>
              <td class="left-padding">
                {{ item.class }}-{{
                  item.shift_id === 1
                    ? "Morning"
                    : item.shift_id === 2
                      ? "Day"
                      : "N/A"
                }}-{{ item.section }}
              </td>
              <td class="right-padding">
                {{ item.amount.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <td
                class="right-padding"
                colspan="4"
              >
                <b>Total Amount</b>
              </td>
              <td class="right-padding">
                <b>{{ totalAmount }}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <tfoot>
          <tr>
            <td>
              <!--place holder for the fixed-position footer-->
              <div class="page-footer-space" />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </section>
</template>
<script>
import { VBToggle, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LocalStorageService from '@/@service/utils/LocalStorage.service'
// import { showLocalDateTime } from '@/@service/utils/utils'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BImg,
    // showLocalDateTime,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    documentData: Object,
    totalAmount: String,
    paymentStatus: Number,
  },
  data() {
    return {
      cmsData: JSON.parse(LocalStorageService.get('pageData')) || '',
      invoiceCopies: ['Bank', 'School', 'Student'],
      currentDateTime: new Date(),
    }
  },

  created() {
    this.cmsData = JSON.parse(LocalStorageService.get('pageData')) || ''
    if (!this.cmsData) {
      LocalStorageService.clear()
      this.$router.push({ name: 'auth-login' })
    }
  },
  methods: {
    // PDF generator using node package jspdf

    // Print invoice using window.print() method javascript default
    printDocument() {
      const pdfTitle = document.title
      const content = document.getElementById('exportArea')
      content.classList.remove('d-none')
      // eslint-disable-next-line no-nested-ternary
      document.title = this.paymentStatus === 1 ? 'Paid' : this.paymentStatus === 2 ? 'Due Report' : 'N/A'
      setTimeout(() => {
        window.print()
        document.title = pdfTitle
        content.classList.add('d-none')
        this.isPrintDocument = false
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  @page {
    //size: landscape;
    margin: 8mm 5mm 8mm 5mm;
    /*    @bottom-left {
      content: counter(page);
    }*/
  }
  .page-header {
    position: fixed;
    top: 0mm;
    width: 100%;
  }
  .page-header,
  .page-header-space {
    align-items: center;
    height: 240px;
  }

  .page-footer,
  .page-footer-space {
    height: 50px;
  }
  .page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .pageContent {
    page-break-after: always;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  /*tfoot :after {
      counter-increment: page;
      content: counter(page);
  }*/

  button {
    display: none;
  }
  /* Images */
  div[class="row"] {
    outline: 1px dotted rgba(0, 77, 0, 0.25);
  }

  div[class^="col-"] {
    background-color: rgba(0, 25, 33, 0.2);
    outline: 1px dotted rgba(0, 0, 0, 0.3);
  }
  // Global Styles
  body {
    background-color: transparent !important;
    //margin: 25mm 25mm 25mm 25mm;
  }
  .page-break {
    page-break-after: always;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .btn-scroll-to-top {
    display: none;
  }
  .custom-search {
    display: none !important;
  }
  .vueTable {
    display: none !important;
  }
  #printTable {
    border: 1px solid black;
  }
  #printTable td,
  th {
    border: 1px solid black;
  }
  #printTable {
    width: 100%;
    border-collapse: collapse;
  }
  .left-padding {
    padding-left: 10px;
  }
  .right-padding {
    text-align: right;
    padding-right: 20px;
  }
  .pos {
    position: absolute;
    left: 0;
  }
}
</style>

<!--{ "id": 1, "name": "Oxford International School","address": "House: 34, Road:-->
<!--27 (Old), New 16, Dhanmondi, Dhaka - 1209", "phone_no": "9115392, 9145587", "mobile_no": "01765855555", "email":-->
<!--"info@ois.edu.bd", "powered_by": "FITL", "logo": "/img/ois-logo.5a7ddb2c.png", -->
<!--"poweredByLogo": "/img/ois-avatar.80050842.png", "favicon": "/img/ois-avatar.80050842.png" }-->
